define(['app', 'columboConfig', 'columboEvents', 'columboMetrics', 'siteObj', '$console', 'webVitals', '$window'], function(app, columboConfig, columboEvents, columboMetrics, siteObj, $console, webVitals, $window) {
  const Columbo = function() {
    const module = {};
    const _columboEvents = columboEvents();
    const _columboMetrics = columboMetrics();

    const _init = function() {
      module.fastenEvents();
      module.fastenPages();
      module.columboEvents.elementClicked(module.getByTriggerType('componentClicked'), module.publishEvent);
      module.columboEvents.elementViewed(module.getByTriggerType('componentViewed'), module.publishEvent);
      module.columboEvents.elementFocused(module.getByTriggerType('componentFocused'), module.publishEvent);
      module.columboEvents.elementHovered(module.getByTriggerType('componentHovered'), module.publishEvent);
      module.columboEvents.createCustomDivSpies(module.filterByTriggerType('spy'));

      webVitals.getCLS(module.columboMetrics.addWebVitalsValue);
      webVitals.getFCP(module.columboMetrics.addWebVitalsValue);
      webVitals.getFID(module.columboMetrics.addWebVitalsValue);
      webVitals.getLCP(module.columboMetrics.addWebVitalsValue);
      webVitals.getTTFB(module.columboMetrics.addWebVitalsValue);
      module.sendPeformance();
    };

    const _getByTriggerType = function(triggerType) {
      return columboConfig
        .find((configElement) => configElement.trigger.type === triggerType);
    };

    const _filterByTriggerType = function(triggerType) {
      return columboConfig
        .filter((configElement) => configElement.trigger.type === triggerType);
    };

    const _fastenEvents = function() {
      return module
        .filterByTriggerType('event')
        .map((configElement) => module.fastenEvent(configElement));
    };

    const _fastenPages = function() {
      return module
        .filterByTriggerType('page')
        .map((configElement) => module.fastenPage(configElement));
    };

    const _fastenEvent = function(configElement) {
      app.subscribe(configElement.trigger.event, function() {
        module.publishEvent(configElement, arguments);
      });
    };


    const _getResourceTiming = () => {
      const resourceEntry = []
      function perfObserver(list) {
        return list.getEntries().forEach((entry) => {
          if(entry.name.includes('https://try.abtasty.com/')) {
            resourceEntry.push({
              'name': entry.name,
              'timeToFirstByte': entry.responseStart - entry.startTime,
              'transfer': entry.responseEnd - entry.responseStart,
              'total': entry.responseEnd - entry.startTime,
              'encodedFileSize': (entry.transferSize/1024).toFixed(2),
            })
          }
        })
      }

      const observer = new PerformanceObserver(perfObserver);
      observer.observe({type: 'resource', buffered: true});
      return resourceEntry
    }

    const _fastenPage = function(configElement) {
      if (module.isDocumentLoaded(configElement.trigger.event)) {
        module.publishEvent(configElement);
      } else {
        window.addEventListener(configElement.trigger.event, () => module.publishEvent(configElement));
      }
    };

    const _isDocumentLoaded = function(event) {
      return event === 'load' && document.readyState === 'complete';
    };

    const _publishEvent = function(configElement, args = null) {
      const eventData = module.columboMetrics.getMetrics(configElement.publish.metrics, args);

      if (siteObj.debugFlag) {
        $console.log('%cColumbo Event: "' + configElement.publish.event + '"', 'background: #222; color: #bada55', eventData);
      }

      eventData.key = configElement.key;
      app.publish(configElement.publish.event, eventData);
    };

    const _sendPeformance = async () => {
      const cwv = {}

    const metricCleanup = (metric) => {
        switch(metric.name) {
          case 'CLS':
            cwv.cumulativeLayoutShift = metric.value;
            break;
          case 'FCP':
            cwv['firstContentfulPaint'] = Math.trunc(metric.value);
            break;
          case 'FID':
            cwv['firstInputDelay'] = Math.trunc(metric.value);
            break;
          case 'LCP':
            cwv['largestContentfulPaint'] = Math.trunc(metric.value);
            break;
          case 'TTFB':
            cwv['timeToFirstByte'] = Math.trunc(metric.value);
            break;
          default:
            cwv[metric.name] = metric.value;
        }
    }

    webVitals.getCLS(metricCleanup, true);
    webVitals.getFCP(metricCleanup);
    webVitals.getFID(metricCleanup);
    webVitals.getLCP(metricCleanup);
    webVitals.getTTFB(metricCleanup);

    window.addEventListener("load", (event) => {
      if(siteObj.features.enableAbtasty){
        $window.dataLayer.push({
          'event': 'elysiumEvent',
          'eventData': {
            'abtasty': module.getResourceTiming(),
            'performance': module.columboMetrics.getPerformanceStats(),
            "webVitals": cwv,
          }
        })
      }
    });
    }

    module.columboEvents = _columboEvents;
    module.columboMetrics = _columboMetrics;
    module.columboConfig = columboConfig;
    module.fastenEvents = _fastenEvents;
    module.fastenPages = _fastenPages;
    module.fastenEvent = _fastenEvent;
    module.fastenPage = _fastenPage;
    module.filterByTriggerType = _filterByTriggerType;
    module.getByTriggerType = _getByTriggerType;
    module.init = _init;
    module.isDocumentLoaded = _isDocumentLoaded;
    module.publishEvent = _publishEvent;
    module.sendPeformance = _sendPeformance;
    module.getResourceTiming = _getResourceTiming;

    module.init();
    return module;
  };

  return Columbo();
});
